/* eslint-disable max-classes-per-file */
export class AxFile {
  create_time?: string;

  filename?: string;

  id?: number;

  info?: AxFileInfo;

  size?: string;

  url?: string;

  originalFile?: File;

  constructor(init: Partial<AxFile>) {
    Object.assign(this, init);
  }
}

export class AxFileInfo {
  os_family_name?: string;

  install_cmd?: string;

  package_name?: string;

  package_version?: string;

  constructor(init: Partial<AxFileInfo>) {
    Object.assign(this, init);
  }
}
